import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import AnimatedLink from "../../components/AnimatedLink"
import rightArrow from "../../images/rightarrow.svg"
export default function jobs({ jobList = [] }) {
  const jobsGrid = (
    <Row>
      {jobList &&
        jobList.map((job, index) => (
          <Col key={index} md="6" lg="6" xl="4" className="py-5">
            <AnimatedLink to={job.route} className="link-no-style text-dark">
              <div className="card text-center">
                <div className="">
                  <h4 className="text-left text-primary d-none d-xl-block">
                    {job.title}
                  </h4>
                  <h4 className="text-left text-primary d-block d-xl-none">
                    {job.title}
                  </h4>
                  <p
                    className="text-left pt-2 pb-3 mb-0 d-none d-xl-block"
                    style={{ height: 160, overflowY: "hidden" }}
                  >
                    {job.shortDesc}
                  </p>
                  <p
                    className="text-left pt-2 pb-3 mb-0 d-block d-xl-none"
                    style={{ height: 140, overflowY: "hidden" }}
                  >
                    {job.shortDesc}
                  </p>

                  <img
                    src={rightArrow}
                    alt="Apply"
                    title="Apply"
                    className="float-left text-primary"
                    srcset=""
                    style={{ width: 22 }}
                  />
                </div>
              </div>
            </AnimatedLink>
          </Col>
        ))}
    </Row>
  )

  return (
    <section id="job-boxes" className="pb-5">
      <Container className="d-none d-lg-block">{jobsGrid}</Container>
      <div className="px-4 d-block d-lg-none">{jobsGrid}</div>
    </section>
  )
}
